import { render, staticRenderFns } from "./ModuleList.vue?vue&type=template&id=1173a802&scoped=true"
import script from "./ModuleList.vue?vue&type=script&lang=js"
export * from "./ModuleList.vue?vue&type=script&lang=js"
import style0 from "./ModuleList.vue?vue&type=style&index=0&id=1173a802&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1173a802",
  null
  
)

export default component.exports