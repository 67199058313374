<template>
  <div>
    <v-card class="ma-2">
      <div class="d-flex justify-space-between px-4">
        <v-card-title>Modules</v-card-title>
        <v-btn class=" my-auto primary" @click="openModal()">Add module</v-btn>
      </div>
    </v-card>
    <v-card class="ma-2">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              №
            </th>
            <th class="text-left">
              keyword
            </th>
            <th class="text-left">
              Name
            </th>
            <!--            <th class="text-left">-->
            <!--              isDeleted-->
            <!--            </th>-->
            <th style="width: 250px"></th>
          </tr>
          </thead>
          <tbody>
          <tr
              class="faq-tr"
              v-for="(item, itemIndex) in Modules"
              :key="itemIndex"
          >
            <td>{{ itemIndex + 1 }}</td>
            <td>{{ item.keyword }}</td>
            <td>{{ item.name }}</td>
            <!--            <td>-->
            <!--              <v-btn icon color="error" v-if="item.isDeleted">-->
            <!--                <v-icon small>mdi-delete</v-icon>-->
            <!--              </v-btn>-->
            <!--            </td>-->
            <td class="w-200">
              <v-row justify="center">
                <v-icon @click="getModule(item.id)"
                        class="cursor-pointer mr-4"
                        style="color: #397dfb">mdi-pencil
                </v-icon>
                <v-icon @click="toggleActivation(item.id)"
                        class="cursor-pointer mr-4"
                        style="color: #ff9800">
                  {{ item.isDeleted ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
                </v-icon>
                <v-icon
                    @click="moveModuleUp(item.id)"
                    class="cursor-pointer"
                    style="color: cornflowerblue">mdi-arrow-up
                </v-icon>
                <v-icon
                    @click="moveModuleDown(item.id)"
                    class="cursor-pointer"
                    style="color: cornflowerblue">mdi-arrow-down
                </v-icon>
                <!--                <v-icon-->
                <!--                    @click="deletePermission(item.id)"-->
                <!--                    class="cursor-pointer">mdi-delete-->
                <!--                </v-icon>-->
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <!--  Modals  -->
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span v-if="update" class="headline">Edit module</span>
          <span v-else class="headline">Add module</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Keyword"
                    outlined
                    v-model="module.keyword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="State"
                    outlined
                    v-model="module.name"
                ></v-text-field>
              </v-col>
              <v-row>
                <v-col>
                  <v-btn style="width: 100%" color="error" @click="dialog = false">
                    close
                    <!-- Закрыть -->
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn style="width: 100%" v-if="update" color="orange" @click="saveModule()">
                    edit
                    <!-- Сохранить -->
                  </v-btn>
                  <v-btn style="width: 100%" v-else color="success" @click="saveModule()">
                    save
                    <!-- Сохранить -->
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ModuleList',
  data() {
    return {
      Modules: [],
      module: {
        id: 0,
        keyword: '',
        name: ''
      },
      dialog: false,
      update: false
    }
  },
  created() {
    // this.getList();
    this.$http.get('Module/getModules').then((res) => {
      // console.log(res)
      this.Modules = res.result.modules
    })
  },
  methods: {
    //   async getList() {
    //     this.showLoader();
    //     try {
    //       const res = await this.$http.get('Region/getCountries');
    //       this.countriesList = res.result
    //     } catch (e) {
    //       this.errorNotification(e.response.data.message)
    //     } finally {
    //       this.hideLoader();
    //     }
    //   },
    async getModule(id) {
      this.update = true;
      try {
        const res = await this.$http.get(`Module/getModule?moduleId=${id}`);
        if (!res.error) {
          // console.log('res.result')
          this.module = res.result
          this.dialog = true;
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async toggleActivation(id) {
      try {
        const res = await this.$http.get(`Module/toggleModuleActivation?moduleId=${id}`);
        if (!res.error) {
          this.successNotification();
          // console.log(res.result.modules);
          this.Modules = res.result.modules
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async saveModule() {
      this.showLoader();
      try {
        const res = await this.$http.post('Module/upsertModule', this.module);
        if (!res.error) {
          this.Modules = res.result.modules;
          this.dialog = false;
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    //   async deletePermission(id) {
    //     try {
    //       const res = await this.$http.post(`Region/Country/delete?id=${id}`);
    //       if (res.isSuccess) {
    //         const item = this.countriesList.findIndex(x => x.id === res.result.actionId);
    //         if (item > -1) {
    //           this.countriesList.splice(item, 1);
    //         }
    //         this.successNotification();
    //       }
    //     } catch (e) {
    //       this.errorNotification(e.response.data.message)
    //     }
    //   },
    async moveModuleUp(id) {
      try {
        const res = await this.$http.get(`Module/moveModuleUp?moduleId=${id}`);
        if (!res.error) {
          this.successNotification();
          this.Modules = res.result.modules
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async moveModuleDown(id) {
      try {
        const res = await this.$http.get(`Module/moveModuleDown?moduleId=${id}`);
        if (!res.error) {
          this.successNotification();
          this.Modules = res.result.modules
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    openModal() {
      this.resetForm();
      this.update = false;
      this.dialog = true;
    },
    resetForm() {
      this.module = {
        id: 0,
        keyword: '',
        name: '',
      }
    }
  },
}
</script>

<style scoped>
.faq-tr:first-child .move-icon-up {
  display: none;
}

.faq-tr:last-child .move-icon-down {
  display: none;
}
</style>
